@import "../../styles/variables";

.app-header {
  flex: 0;
  height: 56px;
  min-height: 56px;
  width: 100%;
  padding: $md;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $color-silver;
  background: #ffffff;
  -webkit-box-shadow: -4px 3px 8px 0px rgba(207,207,207,1);
  -moz-box-shadow: -4px 3px 8px 0px rgba(207,207,207,1);
  box-shadow: -4px 3px 8px 0px rgba(207,207,207,1);

  img {
    height: 40px;
  }
}