@import "../../styles/variables";

.subtitle {
  font-size: 0.8rem;
  color: $color-grey;
}

.delete-button-container {
  margin-left: $md;
}

.react-swipeable-view-container {
  height: 100%;

  > div {
    height: 100%;

    > .MuiTypography-root.MuiTypography-body1 {
      height: 100% !important;

      > div {
        height: 100%;
      }
    }
  }
}
