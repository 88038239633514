@import "../../../styles/variables";

// Table cells

.date-cell {
  display: flex;
  align-items: center;

  img {
    height: 16px;
    margin-right: $md;
  }
}

.step-cell {
  .current-step {
    color: $color-main;
    font-size: 1.1rem;
  }

  .max-step {
    color: $color-grey;
    font-size: 0.8rem;
  }
}

.action-cell {
  cursor: pointer;

  img {
    height: 20px;
    margin-left: $sm;
  }
}

.indicator-cell {
  padding: 0;
  display: flex;
  justify-content: center;

  .indicator {
    border: 3px solid $color-grey;
    border-radius: 14px;
    height: 14px;
    width: 14px;

    &.active {
      border-color: $color-main;
    }

    &.complete {
      background: $color-grey;
    }

    &.complete.active {
      background: $color-main;
    }
  }

  svg {
    width: 20px;
  }
}

.case-action-cell {
  color: $color-grey;

  &.active {
    color: $color-main;
  }
}

.table-header-cell {
  display: flex;
  align-items: center;

  .filter-button {
    //display: none;
  }

  &:hover {
    .filter-button {
      display: block;
    }
  }
}

.filter-menu-item {
  padding-left: $sm !important;
}