@import "variables";
//
//.form-control {
//  background-image: linear-gradient(0deg, $color-main 2px,rgba(0,150,136,0) 0),linear-gradient(0deg,rgba(0,0,0,.26) 1px,transparent 0);
//}

.MuiFormControlLabel-root {
  margin-bottom: 0 !important;
}

.MuiFormControlLabel-label {
  color: $color-grey !important;
  font-size: 0.9rem !important;
  margin-left: 4px !important;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

.MuiTableRow-root:last-child {
  &:not(.MuiTableRow-head) {
    > th, td {
      border-bottom: none !important;
    }
  }
}

.MuiTab-wrapper {
  align-items: flex-start !important;
  padding-left: $md;
  text-transform: capitalize;
}

.MuiAppBar-root.MuiPaper-elevation4 {
  box-shadow: none;
}

@media screen and (min-width: $screen-sm) {
  .MuiPaper-root.MuiDialog-paper.MuiPaper-rounded {
    //background-color: #2b2b2b;
  }
}

.MuiSelect-selectMenu {
  //padding-left: 4px;
}

.MuiDialog-paperWidthSm {
  max-width: 650px;
}

.MuiBadge-anchorOriginTopRightRectangle{
  right: -10px !important;
  top: 5px !important;
}

.MuiBadge-badge {
  font-size: 0.8rem !important;
}

.MuiDialog-paperScrollPaper {
  height: calc(100% - 78px) !important;
}

.MuiDialog-paperFullScreen {
  height: 100% !important;
}

.MuiDialog-paperWidthSm {
  height: auto !important;
}

.MuiExpansionPanel-root.Mui-expanded {
  margin: 0;
}
//
//.MuiSlider-trackInverted .MuiSlider-track {
//  background-color: #d2d2d2 !important;
//}
//
//.MuiSlider-rail {
//  color: #c2c2c2 !important;
//}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperWidthSm.MuiPaper-rounded {
  .MuiDialogTitle-root {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1.1rem !important;
    }
  }
  .MuiDialogContent-root {
    .MuiTypography-root.MuiDialogContentText-root.MuiTypography-colorTextSecondary {
      font-size: 0.9rem !important;
    }
  }
}

.MuiBackdrop-root {
  background-color: 'rgba(0, 0, 0, 0.2) !important';
}

.MuiDialog-paperWidthLg {
  max-width: 1300px !important;
}
