@import '../../../styles/variables';

$circle-radius: 10px;
$circle-color: #e2e2e2;

@media screen and (max-width: $screen-sm) {
  .column {
    display: none;
  }
  .admin-modal-actions {
    padding-bottom: $lg * 1.5 !important;
  }

  .btn-sm {
    margin-top: 16px;
    align-self: flex-start;
  }
}

@media screen and (min-width: $screen-sm) {
  .admin-modal-container {
  }
}

@media screen and (min-width: 700px) {
  .admin-modal-container {
    min-width: 600px;
  }
}

.admin-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > .admin-modal-content {
    padding: $lg;
    flex: 1;
    overflow: auto;
  }

  // MODAL ROW

  .column {
    width: $md * 5;
  }

  .modal-item-circle {
    border: 2px solid $color-grey;
    border-radius: $circle-radius * 2;
    height: $circle-radius * 2;
    width: $circle-radius * 2;
    margin-right: $lg * 1.5;
    margin-top: 2px;

    &.light {
      border-color: $circle-color;
    }
  }

  .sub-item-circle {
    height: $circle-radius;
    width: $circle-radius;
    border-radius: 50%;
    background: $color-grey;
    margin-left: $circle-radius / 2;
  }

  .sub-item-circle-new {
    border: 1px solid $color-grey;
    border-radius: 50%;
    height: $circle-radius;
    width: $circle-radius;
    margin-left: $circle-radius / 2;
  }

  .admin-modal-item {
    display: flex;
    //padding-right: $circle-radius;
    position: relative;

    //&:after {
    //  content: '';
    //  position: absolute;
    //  top: 31px;
    //  left: 9px;
    //  width: 2px;
    //  z-index: -1;
    //}
    //
    //&.header {
    //  &:after {
    //    height: calc(100% - 5px);
    //    background: $circle-color;
    //    top: 20px
    //  }
    //}

    &.empty {
      &:after {
        display: none;
      }
    }

    // MODAL LIST ITEM

    &.list-item-container {
      padding-right: 0;
      display: flex;
      flex-direction: column;

      &:after {
        height: 100%;
        background: $color-grey;
      }

      &:not(:last-child) {
        margin-bottom: $md;
      }

      &:last-child:after {
        display: none;
      }
    }

    .list-item-main {
      align-items: center;
      margin-top: 3px;
      display: flex;
      flex: 1;
    }

    // MODAL LIST OF SUB-ITEMS

    .sub-items-list {
      .sub-item-header-container {
        display: flex;
        align-items: center;

        .sub-item-header {
          padding: $md 0 $sm;
          border-bottom: $color-silver 1px solid;
          color: $color-main;
          font-size: 0.8rem;
          display: flex;
          flex: 1;
          justify-content: space-between;
        }
      }

      .sub-item {
        margin-top: $md;
        display: flex;
        align-items: center;
        font-size: 0.9rem;

        .user-email {
          color: $color-grey;
          margin-left: $lg;
          font-size: 0.8rem;
        }

        .delete-icon {
          display: none;
          color: $color-main;
          font-size: 0.8rem;
          transition: 1s ease-in-out;
        }

        &:hover {
          .delete-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: $sm;
            cursor: pointer;
            transition: 1s ease-in-out;

            svg {
              height: 18px;
              width: 18px;
            }
          }
        }
      }

      .MuiFormControl-root {
        margin-left: 3px;
      }
    }

    .modal-item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      border-bottom: $color-grey 1px solid;
      min-height: 45px;
    }

    .modal-item-header-user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      min-height: 45px;
    }

    .modal-item-content {
      flex: 1;

      .item-content-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .country-select {
        margin-top: $md;
        width: 100%;
      }
    }
  }

  .user-select {
    width: 100%;

    > div {
      &:first-child,
      &:nth-child(2) {
        width: 100%;
      }
    }
  }

  .user-email {
    color: $color-grey;
    margin-left: $lg;
    font-size: 0.8rem;
  }

  .info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .info-row-field {
      flex: 1;
      font-size: 0.8rem;
      margin-top: $md;

      .field-label {
        color: $color-grey;
      }

      .field-value {
        padding-bottom: $sm;
        border-bottom: 1px solid $color-silver;
      }

      &:only-child {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      &:first-child {
        margin-right: $md;
      }

      &:last-child {
        margin-left: $md;
      }
    }
  }

  .new-item {
    margin-top: $lg;
    align-items: center;

    .modal-item-circle {
      border: 2px dashed $color-grey;
    }
  }

  // MODAL TABS

  .modal-tabs {
    margin: $lg * 2 0 $lg;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .modal-tab {
      background: $color-grey;
      padding: $sm / 2;
      color: $color-white;
      margin-right: $md;
      width: 120px;
      border-radius: 25px;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      margin-bottom: $md;

      &.active {
        background: $color-main;
      }
    }
  }

  // MODAL ACTIONS

  .admin-modal-actions {
    padding: $lg;
    width: 100%;
    border-top: 1px solid $color-silver;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // DOCUMENTS

  .documents-list {
    margin-top: 3px;
    padding: 0;

    .documents-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      a {
        color: $color-main !important;
        text-decoration: none;
      }

      img {
        margin-right: $md;
        width: 20px;
      }
    }
  }

  .products-container {
    margin-top: 3px;
    padding: 0;

    .products-list {
      align-items: center;
    }

    .products-form {
      width: 85%;
    }
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.select-item-row {
  margin-top: $md;
  display: flex;
  width: 100%;

  .select-item {
    flex: 1;
    width: 200px;

    &:only-child {
      margin-left: 0 !important;
      margin-right: 0 !important;
      flex: 0.5;
      padding-right: 16px;
    }

    &:first-child {
      margin-right: $md;
    }

    &:last-child {
      margin-left: $md;
    }

    .select-label {
      color: $color-main;
      font-size: 0.8rem;
      text-transform: uppercase;
    }
  }

  .user-select-container {
    > div {
      width: 100%;
    }
  }
}
