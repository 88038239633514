@import "../../styles/variables";

.app-footer {
  flex: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: $color-dark;
  color: $color-white;
  padding: $lg * 2 0;

  > div {
    padding: 0 $lg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 380px;

    > div.primary {
      text-decoration: underline;
      margin-left: $lg * 2;
    }
  }

  .divider {
    height: 13px;
    border-right: 1px solid $color-main;
    margin-left: $md;
    margin-right: $md;
  }
}

.footer-modal__content {
  padding: $lg * 2;

  > .footer-modal__block:not(:first-child) {
    margin-top: $lg;
  }

  .footer-modal__block {
    > .title {
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: $sm;
    }
  }
}
