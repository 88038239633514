@import '../../../styles/variables';

$circle-radius: 10px;
$circle-color: #e2e2e2;

@media screen and (max-width: $screen-sm) {
  .column {
    display: none;
  }
  .case-modal-container {
    height: 100%;
  }
  .case-modal-actions {
    padding-bottom: $lg * 1.5 !important;
  }
  .step-4 {
    display: flex;
    flex: 1;
    height: 100%;
  }
  .axis-diagram {
    > .image-container {
      height: 350px !important;

      img {
        height: 350px !important;
      }
    }
  }
  .step-4-container {
    .image-container {
      &.coronal {
        > div {
          height: 220px !important;

          img {
            height: 220px !important;
          }
        }

        &.left_open {
          .arrow-slider-container {
            top: 42px !important;
            left: 33px !important;
          }
        }

        &.left_close {
          .arrow-slider-container {
            top: 46px !important;
            left: 34px !important;
          }
        }

        &.right_open {
          .arrow-slider-container {
            top: 40px !important;
            left: 23px !important;
          }
        }

        &.right_close {
          .arrow-slider-container {
            top: 32px !important;
            left: 26px !important;
          }
        }

        .arrow-slider {
          width: 121px !important;
        }
      }

      &.sagittal {
        img {
          height: 230px !important;
          margin-bottom: $lg;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .filter-modal {
    min-width: 400px !important;
  }
}

// Small screen

@media screen and (min-width: 600px) and (max-width: 1200px) {
  .axis-diagram {
    > .image-container {
      height: 350px !important;

      img {
        height: 350px !important;
      }
    }
  }

  .coronal {
    > div {
      height: 250px !important;

      img {
        height: 250px !important;
      }
    }

    &.left_open {
      .arrow-slider-container {
        top: 50px !important;
        left: 39px !important;
      }
    }

    &.left_close {
      .arrow-slider-container {
        top: 51px !important;
        left: 39px !important;
      }
    }

    &.right_open {
      .arrow-slider-container {
        top: 47px !important;
        left: 31px !important;
      }
    }

    &.right_close {
      .arrow-slider-container {
        top: 37px !important;
        left: 35px !important;
      }
    }

    .arrow-slider {
      width: 138px !important;
    }
  }

  .sagittal {
    img {
      height: 280px !important;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .axis-diagram {
    > .image-container {
      height: 430px !important;

      img {
        height: 430px !important;
      }
    }
  }

  .coronal {
    > div {
      height: 300px !important;

      img {
        height: 300px !important;
      }
    }

    &.left_open {
      .arrow-slider-container {
        top: 61px !important;
        left: 49px !important;
      }
    }

    &.left_close {
      .arrow-slider-container {
        top: 64px !important;
        left: 49px !important;
      }
    }

    &.right_open {
      .arrow-slider-container {
        top: 56px !important;
        left: 43px !important;
      }
    }

    &.right_close {
      .arrow-slider-container {
        top: 49px !important;
        left: 47px !important;
      }
    }

    .arrow-slider {
      width: 166px !important;
    }
  }

  .sagittal {
    img {
      height: 280px !important;
    }
  }
}

@media screen and (min-width: 1500px) and (max-width: 1900px) {
  .axis-diagram {
    .image-container {
      height: 420px !important;

      img {
        height: 420px !important;
      }
    }
  }

  .coronal {
    > div {
      height: 460px !important;

      img {
        height: 460px !important;
      }
    }

    &.left_open {
      .arrow-slider-container {
        top: 103px !important;
        left: 81px !important;
      }
    }

    &.left_close {
      .arrow-slider-container {
        top: 106px !important;
        left: 81px !important;
      }
    }

    &.right_open {
      .arrow-slider-container {
        top: 94px !important;
        left: 84px !important;
      }
    }

    &.right_close {
      .arrow-slider-container {
        top: 85px !important;
        left: 89px !important;
      }
    }

    .arrow-slider {
      width: 255px !important;
    }
  }

  .sagittal {
    img {
      height: 440px;
    }
  }
}

@media screen and (min-width: 1900px) {
  .axis-diagram {
    > .image-container {
      height: 580px !important;

      img {
        height: 580px !important;
      }
    }
  }

  .coronal {
    > div {
      height: 590px !important;

      img {
        height: 590px !important;
      }
    }

    &.left_open {
      .arrow-slider-container {
        top: 134px !important;
        left: 106px !important;
      }
    }

    &.left_close {
      .arrow-slider-container {
        top: 137px !important;
        left: 106px !important;
      }
    }

    &.right_open {
      .arrow-slider-container {
        top: 120px !important;
        left: 118px !important;
      }
    }

    &.right_close {
      .arrow-slider-container {
        top: 106px !important;
        left: 125px !important;
      }
    }

    .arrow-slider {
      width: 327px !important;
    }
  }

  .sagittal {
    img {
      height: 550px !important;
    }
  }
}

//@media screen and (min-width: 2300px) {
//  .axis-diagram {
//    img {
//      height: 630px !important;
//    }
//  }
//
//  .coronal {
//    > div {
//      height: 600px !important;
//
//      img {
//        height: 600px !important;
//      }
//
//      .arrow-slider-container {
//        top: 120px !important;
//        left: 145px !important;
//      }
//    }
//  }
//
//  .sagittal {
//    img {
//      height: 500px !important;
//    }
//  }
//}

@media screen and (min-width: $screen-sm) {
  .case-modal-container {
    height: 100%;

    .case-step-row {
      display: flex;
      flex: 0 1 auto;
      flex-shrink: 0;
    }
  }
}

@media screen and (min-width: 700px) {
  .case-modal-container {
    width: 650px;
    min-width: 650px;
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  min-width: 300px;
  width: 100%;
}

.case-modal-container {
  display: flex;

  //&.no-tabs {
  //  padding: $md $md 0 !important;
  //}

  .case-progress-container {
    width: 100%;

    .case-step-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .case-step-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: $lg;
        overflow: auto;
      }

      // MODAL ROW

      .column {
        width: $md * 5;
      }

      .modal-item-circle {
        border: 2px solid $color-grey;
        border-radius: $circle-radius * 2;
        height: $circle-radius * 2;
        width: $circle-radius * 2;
        margin-right: $lg * 1.5;
        margin-top: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-grey;
        font-weight: bold;
        font-size: 0.75rem;

        &.light {
          border-color: $circle-color;
        }
      }

      .modal-item-content {
        flex: 1;

        .modal-item-header {
          display: flex;
          justify-content: space-between;
        }

        .modal-item-block {
          display: flex;
          flex-direction: column;
          margin-top: $lg;

          &:not(:last-child) {
            margin-bottom: $lg * 1.5;
          }

          .block-header {
            width: 100%;
            margin-bottom: $md;
            background: $color-grey;
            color: $color-white;
            text-align: center;
            font-size: 0.8rem;
            text-transform: uppercase;
            border-radius: 10px;
          }

          .required::before {
            content: '\002A';
            color: $color-white;
            top: 0;
            left: 0;
            font-size: 16px;
            line-height: 0;
          }

          .field-container {
            .field-title {
              margin-bottom: $sm;
              font-size: 0.8rem;
              color: $color-grey;
              text-transform: uppercase;
            }

            .user-select {
              width: 100%;
            }

            .initials-field {
              width: 30px;

              input {
                text-align: center;
              }
            }

            .step0-surgeon-name {
              padding-bottom: 7px;
              border-bottom: 1px solid $color-grey;
            }
          }

          .block-file-list {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
              color: inherit;
              text-decoration: inherit;
            }

            img {
              height: 20px;
              margin-right: $md;
            }
          }
        }
      }
    }
  }

  .radio-group-container {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;

      > * {
        flex: 1;
      }
    }
  }

  .case-activity-container,
  .case-files-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $lg;

    .case-data-list {
      flex: 1;

      .activity-list-item {
        display: flex;
        align-items: center;
        padding: $lg 0;
        border-bottom: 1px solid $color-silver;

        &:first-child {
          padding-top: 0;
        }

        img {
          height: 20px;
        }

        .list-item-title {
          word-break: break-word;
        }

        .list-item-date {
          margin-top: $md;
          font-size: 0.8rem;
          color: $color-grey;
        }
      }

      .data-list-item {
        display: flex;
        align-items: center;
        padding: $lg 0;
        border-bottom: 1px solid $color-silver;

        .icon-file-container {
          display: flex;
          align-items: center;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border: none;
        }

        img {
          height: 24px;
        }

        .list-item-main {
          margin-left: $lg * 2;

          .list-item-title {
            word-break: break-word;
          }

          .list-item-date {
            font-size: 0.8rem;
            color: $color-grey;
          }
        }

        .list-item-type {
          font-size: 0.8rem;
          color: $color-grey;
          //text-transform: capitalize;
        }
      }
    }

    .empty-list {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      color: $color-grey;

      img {
        height: 100px;
        margin-bottom: $md;
      }
    }
  }

  .case-file-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: $md;
    }

    img {
      height: 20px;
      margin-right: $md;
    }
  }

  .case-accordion {
    margin-top: $lg;
    width: 100%;

    .accordion-step-header {
      width: 100%;
      display: flex;
      align-items: center;

      .accordion-step-circle {
        border: 1px solid $color-main;
        background: $color-main;
        color: $color-white;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
      }

      .accordion-title {
        color: $color-main;
      }

      .accordion-title-date {
        color: $color-grey;
        font-size: 0.8rem;
        margin-left: $md;
      }
    }
  }

  .case-static-container {
    //padding: $sm;
    font-size: 0.9rem;
    flex: 1;

    .field-container {
      margin-bottom: $md;

      .field-title {
        font-size: 0.8rem;
        color: $color-grey;
        text-transform: uppercase;
      }

      .field-value {
        font-size: 0.9rem;
      }
    }

    img {
      width: 20px;
      margin-right: $md;
    }
  }

  .case-modal-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $lg;
    width: 100%;
    border-top: 1px solid $color-silver;

    .step-0-buttons {
      button {
        min-width: 135px !important;

        &:last-child {
          margin-left: $md;
        }
      }
    }

    .step-4-buttons {
      button {
        width: 125px !important;
        min-width: 125px !important;

        &:last-child {
          margin-left: $md;
        }
      }
    }
  }

  .status-indicator {
    font-size: 0.8rem;
    text-align: center;
    margin-left: $lg;
    margin-right: $md;
    padding: 1px $md;
    border-radius: 15px;
    border: 1px $color-grey solid;
    color: $color-grey;
    background: $color-silver;
    text-transform: capitalize;
    font-weight: bold;
    //min-width: 48px;

    &.status-indicator-file {
      padding: 1px $lg * 2;
    }

    &.pending {
      border-color: $color-orange;
      color: $color-orange;
      background-color: rgb(255, 244, 229);
    }

    &.approved {
      border-color: $color-success;
      color: $color-success;
      background-color: rgb(237, 247, 237);
    }

    &.rejected {
      border-color: $color-danger;
      color: $color-danger;
      background-color: rgb(253, 236, 234);
    }
  }

  .cases-no-access {
    padding: $lg;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: $color-grey;
    text-align: center;

    &.small {
      padding: 0;
      font-size: 0.9rem;
    }
  }

  .calculation-input {
    > div {
      font-size: 0.9rem;
    }

    input {
      text-align: right;
    }

    .calculation-input-label {
      min-width: 80px;
    }

    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
      margin-left: 0;
    }
  }

  .axis-diagram-labels {
    display: flex;
    justify-content: center;

    > div:first-child {
      margin-right: $lg * 3.5;
    }

    > div:last-child {
      margin-left: $lg * 3.5;
    }
  }

  .axis-diagram {
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center;
    height: calc(100% - 90px);

    .image-container {
      position: relative;
      height: 100%;
      width: fit-content;
      display: flex;
      align-items: center;

      img {
        height: 100%;
        min-height: 200px;
      }

      .axis-diagram-field {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.9rem;
        position: absolute;

        .axis-diagram-label {
          width: 75px;
          font-size: 12px;

          span {
            &.orange {
              color: $color-orange;
            }

            &.green {
              color: $color-success;
            }

            &.red {
              color: $color-danger;
            }
          }
        }

        &.LPFA {
          display: none;
          &.left {
            top: 0;
            left: -20%;
          }
          &.right {
            top: 0;
            left: -20%;
          }
        }

        &.mLDFA {
          &.left {
            left: 30%;
            top: 40%;
          }
          &.right {
            right: 25%;
            top: 35%;
          }
        }

        &.LDTA {
          display: none;
          &.left {
            top: 90%;
            left: -20%;
          }
          &.right {
            top: 90%;
            left: -20%;
          }
        }

        &.MPTA {
          &.left {
            left: -10%;
            top: 64%;
          }
          &.right {
            right: -25%;
            top: 62%;
          }
        }

        &.PPFA {
          display: none;
          &.left {
            top: 0;
            left: 0;
            right: -10%;
          }
          &.right {
            top: 0;
            left: 0;
            right: -10%;
          }
        }

        &.ANSA {
          display: none;
          &.left {
            top: 5%;
            right: -20%;
          }
          &.right {
            top: 5%;
            right: -20%;
          }
        }

        &.PDFA {
          &.left {
            right: -25%;
            top: 30%;
          }
          &.right {
            left: -25%;
            top: 32%;
          }
        }

        &.PPTA {
          &.left {
            top: 60%;
            right: -25%;
          }
          &.right {
            top: 60%;
            left: -24%;
          }
        }

        &.ADTA {
          display: none;
          &.left {
            top: 90%;
            right: -20%;
          }
          &.right {
            top: 90%;
            right: -20%;
          }
        }

        &.JLCA {
          &.left {
            left: -10%;
            top: 42%;
          }
          &.right {
            right: -20%;
            top: 40%;
          }
        }

        .axis-diagram-value {
          &.orange {
            color: $color-main;
          }

          &.green {
            color: $color-success;
          }

          &.red {
            color: $color-danger;
          }
        }
      }
    }
  }

  .step-4-container {
    height: 100%;
    justify-content: space-between;

    .values-list {
      display: flex;
      align-items: flex-start;
      font-size: 0.9rem;
      height: 150px;
    }

    .image-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: $md * 1.5;
      padding-bottom: $lg * 1.5;
      flex: 1;

      &.coronal {
        min-height: 200px;
        padding: $lg;

        > div {
          height: 100%;
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
            width: initial !important;
          }
        }
      }

      &.sagittal {
        min-height: 200px;
        padding: $lg * 2 0;

        img {
          width: initial !important;
          margin-bottom: $lg;
        }
      }

      > div {
        position: relative;
      }

      .arrow-slider-container {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 25px;
        left: 6px;

        .arrow-slider {
          border-radius: 20px;
          width: 146px;
          border: 1px solid #b1b1b1;
          height: 4px;
          position: relative;

          .arrow {
            position: absolute;
            top: -17px;
          }
        }
      }
    }

    .alignment-data-item {
      padding-bottom: $sm;
      margin-bottom: $sm;
      border-bottom: 1px solid $color-silver;

      > div {
        padding: $sm $md + 2px;
        display: flex;
        justify-content: space-between;
      }

      .alignment-data-value {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.active {
        > div {
          padding: $sm / 2 $md;
          border: 2px solid $color-main;
          border-radius: 20px;

          .alignment-data-value {
            color: $color-main;
            font-weight: bold;
          }
        }
      }
    }
  }

  .summary-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: $sm 10px $md;
    border-bottom: 1px solid $color-silver;
    font-size: 0.9rem;

    &:not(:first-child) {
      margin-top: $sm !important;
    }
  }

  .summary-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-grey;
    padding: $md;
    border-bottom: solid 1px $color-grey;
    margin-bottom: $md;

    > .status-indicator {
      margin: 0;
    }
  }

  .cases-arrow-left {
    position: absolute;
    left: $lg;
    cursor: pointer;
  }

  .cases-arrow-right {
    position: absolute;
    right: $lg;
    cursor: pointer;
  }

  .hardware-list-container {
    margin-top: $lg;

    .category-select {
      margin-right: $md;
    }

    .hardware-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $sm 0;
      margin-bottom: $sm;
      border-bottom: 1px solid $color-grey;
    }

    .new-hardware {
      margin-top: $md;
      display: flex;
      align-items: center;
      font-size: 0.9rem;
    }

    .hardware-list {
      font-size: 0.9rem;
    }

    .hardware-amount {
      width: 40px;
      margin: 0 $md;

      input {
        text-align: center;
      }
    }
  }

  .manufacturing-list-container {
    margin-top: $lg;

    .manufacturing-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $sm;
      border-bottom: 1px solid $color-grey;
    }

    .manufacturing-list {
      font-size: 0.9rem;

      & .amout {
        text-align: center;
        font-weight: bold;
      }
    }
  }
}

.alignment-data-item {
  padding-bottom: $sm;
  margin-bottom: $sm;
  border-bottom: 1px solid $color-silver;
  font-size: 0.9rem;

  > div {
    padding: $sm $md + 2px;
    display: flex;
    justify-content: space-between;
  }

  .alignment-data-value {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.block-header {
  width: 100%;
  margin-bottom: $md;
  background: $color-grey;
  color: $color-white;
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  border-radius: 10px;
}

.wbl-input {
  margin-top: $md;
  > div > div > div:first-child {
    padding-left: $md;
  }
  input {
    font-weight: bold;
  }
}

.case-export-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .case-export-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: $lg;
    border-top: 1px solid $color-silver;
  }

  .address-input {
    .field-content {
      > div {
        width: 100%;
      }
    }
  }

  .phone-input {
    .field-content {
      > div {
        width: 50%;
      }
    }
  }

  .field-container {
    .field-title {
      margin-bottom: $sm;
      font-size: 0.8rem;
      color: $color-grey;
      text-transform: uppercase;
    }
  }
}

.case-notes-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: $md;

  .case-notes-list {
    flex: 1;
    overflow: auto;
  }

  .case-note {
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $md;
    }

    margin-left: $lg;
    margin-right: $lg;
    margin-bottom: $md;
    padding-top: $md;
    padding-bottom: $lg;

    border-bottom: 1px solid $color-silver;
  }

  .case-notes_new-note {
    padding: $lg;

    > div {
      width: 100%;
    }

    .case-notes_new-note-action {
      padding-top: $lg;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}


.case-file-list-item {
  padding-top: $sm;
  padding-bottom: $md;
  margin-bottom: $sm;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $color-silver;
  }
}
