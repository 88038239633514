@import "variables";

// Text color
.primary {
  color: $color-main;
}
.secondary {
  color: $color-grey;
}
.danger {
  color: $color-danger;
}

.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}

.width-50 {
  width: 50%;
}

// Flex-box
.d-flex {
  display: flex;
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-start {
  justify-content: flex-start;
}
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.direction-column {
   flex-direction: column;
}
.direction-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

// Spacing
.margin-0 {
  margin: 0 !important;
}

.m-sm {
  margin: $sm !important;
}
.m-b-sm {
   margin-bottom: $sm !important;
 }
.m-t-sm {
  margin-top: $sm !important;
}
.m-l-sm {
  margin-left: $sm !important;
}
.m-r-sm {
  margin-right: $sm !important;
}

.m-md {
  margin: $md !important;
}
.m-b-md {
  margin-bottom: $md !important;
}
.m-t-md {
  margin-top: $md !important;
}
.m-l-md {
  margin-left: $md !important;
}
.m-r-md {
  margin-right: $md !important;
}

.m-lg {
  margin: $lg !important;
}
.m-b-lg {
  margin-bottom: $lg !important;
}
.m-t-lg {
  margin-top: $lg !important;
}
.m-l-lg {
  margin-left: $lg !important;
}
.m-r-lg {
  margin-right: $lg !important;
}

.padding-0 {
  padding: 0 !important;
}

.p-sm {
  padding: $sm !important;
}
.p-b-sm {
  padding-bottom: $sm !important;
}
.p-t-sm {
  padding-top: $sm !important;
}
.p-l-sm {
  padding-left: $sm !important;
}
.p-r-sm {
  padding-right: $sm !important;
}

.p-md {
  padding: $md !important;
}
.p-b-md {
  padding-bottom: $md !important;
}
.p-t-md {
  padding-top: $md !important;
}
.p-l-md {
  padding-left: $md !important;
}
.p-r-md {
  padding-right: $md !important;
}

.p-lg {
  padding: $lg !important;
}
.p-b-lg {
  padding-bottom: $lg !important;
}
.p-t-lg {
  padding-top: $lg !important;
}
.p-l-lg {
  padding-left: $lg !important;
}
.p-r-lg {
  padding-right: $lg !important;
}

// Form
.form-group-container {
  display: flex;
  align-items: center;
  position: relative;

  > div:only-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  > div:not(:last-child) {
    margin-right: $md;
  }

  > div:last-child {
    margin-left: $md;
  }

  > img {
    height: 20px;
    width: 36px;
    padding-right: $lg;
  }
}

// Other
.badge {
  background: $color-silver;
  color: $color-grey;
  background: #f2f2f2;
  padding: $sm $md;
  margin-left: $md;
  border-radius: 10px;
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.font-size-xs {
  font-size: 0.8rem;
}
.font-size-sm {
  font-size: 0.9rem;
}
.font-size-bg {
  font-size: 1.1rem;
}
.font-size-lg {
  font-size: 1.2rem;
}

.font-bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

.underline, .border-bottom {
  border-bottom: 1px solid $color-silver;
}

.border-top {
  border-top: 1px solid $color-silver;
}

.empty-block {
  padding: $lg * 2;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: $color-grey;
  text-align: center;

  img {
    height: 48px;
    margin-bottom: $md;
  }

  > .additional-text {
    font-size: 1.1rem;
  }
}

.alert {
  margin: $md 0 $sm;
  padding: $md $lg;
  font-size: 0.875rem;
  border-radius: $sm;
  color: $color-grey;
  background: $color-silver;

  svg {
    margin-right: $md;
  }

  &.warning {
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);

    //svg {
    //  color: $color-main !important;
    //}
  }
  &.success {
    color: rgb(30, 70, 32);
    background-color: rgb(237, 247, 237);
  }
  &.error {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);
  }
}

.button-processing-indicator {
  color: $color-main;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.default-styled-link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
