@import "../../styles/variables";

@media screen and (max-width: $screen-sm) {
  .sign-up-form-container {
    justify-content: flex-start !important;
  }
}

.sign-up-page {
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  > img {
    width: 100px;
    margin: $md;
  }

  .sign-up-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $md;
    width: 100%;
    max-width: 380px;
    padding: $lg;

    h3 {
      font-weight: bold;
    }

    .sign-up-form {
      > .divider {
        border-bottom: 1px solid $color-grey;
        margin-bottom: $md;
        margin-top: $lg;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: $lg * 2;

      button {
      }
    }
  }
}
