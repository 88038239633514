@import "../../styles/variables";

@media screen and (max-width: $screen-sm) {
  .login-form-container {
    justify-content: flex-start !important;
  }
}

.login-page {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  > img {
    width: 100px;
    margin: $md;
  }

  .login-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $md;
    width: 100%;
    max-width: 380px;
    padding: $lg;

    h3 {
      font-weight: bold;
    }

    .login-form {
      > .divider {
        border-bottom: 1px solid $color-grey;
        margin-bottom: $md;
        margin-top: $lg;
      }

      .forgot-password {
        position: absolute;
        right: 0;
        top: 0;
        color: #ee9321;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: $lg * 2;

      > div {
        flex: 1;
      }

      .checkbox-container {
        display: flex;
        align-items: center;
        color: $color-grey;
      }
    }
  }
}

.MuiDialog-paperScrollPaper {
  height: auto !important;
}
