@import "../../styles/variables";

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;

  .sidebar-toggle {
    margin-right: 16px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-silver;

    svg {
      fill: $color-grey;
    }
  }

  .sidebar-main {
    display: flex;
    flex-direction: column;

    &.sidebar-mobile {
      .MuiListItem-gutters {
        margin-left: $lg;
        margin-bottom: $md;
        padding: $sm $lg $sm $lg;
      }
    }

    .sidebar-logo {
      cursor: pointer;
      height: 45px;
      margin: 16px 24px 16px 40px;
    }

    .sidebar-logo__minimized {
      cursor: pointer;
      width: 30px;
      margin: $lg * 2 auto;
    }

    MuiListItem-gutters:hover {
      background-color: $color-silver;
    }

    .MuiListItem-gutters {
      cursor: pointer;
      margin-left: $lg * 1.5;
      margin-right: $lg;
      margin-bottom: $md;
      border-radius: 12px;
      width: auto;
      transition: 0.2s;
      max-height: 48px;
      color: $color-grey;

      span {
        font-weight: 600;
      }

      &:hover {
        background-color: $color-silver;
      }

      &.active-item {
        background-color: $color-silver;
        color: $color-main;

        //color: $color-dark;
        //border-right: 5px solid $color-main;
        //-webkit-box-shadow: -4px 3px 8px 0px rgba(207,207,207,1);
        //-moz-box-shadow: -4px 3px 8px 0px rgba(207,207,207,1);
        //box-shadow: -4px 3px 8px 0px rgba(207,207,207,1);

        //.sidebar-badge {
        //  margin-right: -5px;
        //}
      }

      &.active-training-item {
        color: $color-main;

        .sidebar-training-badge {
          border: 1px solid $color-main;
          background-color: #ddeaf8;
          color: $color-main;
          padding: 1px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          font-size: 0.7rem;
        }
      }

      .MuiListItem-root {
        width: auto !important;
      }

      .sidebar-item-icon {
        width: 25px;

        &.icon-mobile {
          width: 18px;
        }
      }
    }

    &.sidebar-main__collapsed {
      //width: 80px;

      .MuiListItem-gutters {
        margin-right: $lg * 1.5;
      }
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: $lg 0;

    .user-name-container {
      margin-bottom: $lg * 2;
      display: flex;
      flex-direction: column;
      align-items: center;

      .user-name {
        font-size: 1.1rem;
      }

      .user-role {
        color: $color-grey;
      }
    }

    .user-action {
      color: $color-main;
      cursor: pointer;
      font-size: 1.1rem;

      &:last-child {
        margin-bottom: $lg;
      }
    }

    .sign-out-container {
      cursor: pointer;
      margin-bottom: $lg;

      img {
        height: 30px;
      }
    }
  }

  .sidebar-badge {
    height: 24px;
    border: 1px solid $color-main;
    background-color: #ddeaf8;
    color: $color-main;
    padding: 1px 12px;
    font-size: 0.8rem;
    display: flex;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
  }
}
