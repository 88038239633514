// Colors
$color-main: rgb(33, 124, 238);
$color-dark: #333333;
$color-white: #ffffff;
$color-grey: #b1b1b1;
$color-silver: #f2f2f2;
$color-success: #15AA1A;
$color-danger: #d32f2f;
$color-orange: #ee9321;

// Spacing
$sm: 4px;
$md: 8px;
$lg: 16px;
$xl: 32px;

// Breakpoints
$screen-xs: 0px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-large: 1280px;
$screen-xl: 1920px;
