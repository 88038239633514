@import '../../../styles/variables';

.MuiDialogContent-root {
  height: 90% !important;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.documents-list {
  flex: 1;
  padding: $lg * 1.5;

  .documents-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding-bottom: $md;

    a {
      color: $color-main;
      text-decoration: none;
    }

    img {
      margin-right: $md;
      width: 20px;
    }
  }
}

.modal-actions {
  padding: $lg;
  width: 100%;
  border-top: 1px solid $color-silver;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 700px) {
  .modal-container {
    min-width: 400px;
  }
}
