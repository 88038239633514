@import "../../styles/variables";

// General
.table-title-container {
  padding: $lg $lg 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .table-title {
    font-size: 1.2rem;
    min-width: 120px;
  }

  .table-action {
    color: $color-main;
    cursor: pointer;
  }

  tr:last-child > td {
    border-bottom: 0;
  }
}

.cases-table-container {
  padding: $md;

  .case-search {
    width: 220px;
  }
}

.cases-table-mobile {
  margin-top: $md;
  padding: $md;

  .case-mobile-row {
    padding: $lg $md;
    cursor: pointer;
    border-top: 1px solid $color-silver;

    &:hover {
      background-color: rgb(255, 244, 229);
    }
  }
}

// Steps

.case-steps-container {
  padding: $md 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .case-step {
    height: 6px;
    flex: 1;
    background: $color-grey;
    border-radius: 10px;
    box-sizing: content-box;

    &:not(:last-child) {
      margin-right: $sm * 1.5;
    }

    &.new {
      border: 1px solid $color-white;
    }

    &.active {
      background: $color-white;
      border: 2px solid $color-main;
    }

    &.completed {
      background: $color-main;
    }
  }
}

.filter-list {
  display: flex;
  align-items: center;
  padding: $lg * 1.5 $lg 0;

  > img {
    height: 20px;
    margin-right: $sm;
  }

  .filter-list-item-container {
    display: flex;

    .filter-list-item {
      background: $color-silver;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 6px 2px 8px;
      color: $color-grey;
      //color: $color-main;
      margin: 0 $sm;
      border-radius: 8px;
      border: 1px solid $color-main;
      font-size: 15px;

      .filter-list-item-value {
        color: #000000;
      }

      svg {
        height: 14px;
        cursor: pointer;
        width: 14px;
        margin-left: 4px;
        fill: $color-main;
      }
    }
  }
}
